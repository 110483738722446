import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Footer = () => {

    const { t } = useTranslation();

    return (

        <footer className="p-4 bg-primary text-center">
            <Typography color="white" variant="body1" component="div">{t('© 2022 All rights reserved. Developed by the NYMZ Group.')}</Typography>
        </footer>

    );
};

export default Footer;
