
import { MenuItem, Button, Container, Menu, Typography, IconButton, Toolbar, Box, AppBar } from '@mui/material'; 
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';

const Nav = () => {
    
    const [anchorElNav, setAnchorElNav] = useState(null);
    const { t } = useTranslation();

    let navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar sx={styles.appBar} position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    
                    <Box sx={styles.mobile.box}>
                        
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit">
                            <MenuIcon />
                        </IconButton>
                        
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}>
                            
                            <MenuItem onClick={() => { navigate("/שעון", { replace: true }) }}>
                                <Typography textAlign="center">{t('Countries time')}</Typography>
                            </MenuItem>

                            <MenuItem onClick={() => { navigate("/לוח-שנה", { replace: true }) }}>
                                <Typography textAlign="center">{t('Calendar')}</Typography>
                            </MenuItem>

                            <MenuItem onClick={() => { navigate("/זמני-שבת", { replace: true }) }}>
                                <Typography textAlign="center">{t('Shabbat times')}</Typography>
                            </MenuItem>

                        </Menu>
                    </Box>
                    
                    <Box sx={styles.desktop.box}>
                        <Button onClick={() => { navigate("/שעון", { replace: true }) }} sx={{ my: 2, color: "white", display: "block" }}>{t('Countries time')}</Button>
                        <Button onClick={() => { navigate("/לוח-שנה", { replace: true }) }} sx={{ my: 2, color: "white", display: "block" }}>{t('Calendar')}</Button>
                        <Button onClick={() => { navigate("/זמני-שבת", { replace: true }) }} sx={{ my: 2, color: "white", display: "block" }}>{t('Shabbat times')}</Button>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
};

const styles = {

    appBar: {
        boxShadow: 'unset',
    },

    desktop: {

        box: {
            flexGrow: 1,
            display: {
                xs: "none",
                md: "flex"
            }
        }

    },

    mobile: {

        box: {
            flexGrow: 1,
            display: {
                xs: "flex",
                md: "none"
            }
        }
    }

};

export default Nav;
