import { Routes, Route } from "react-router-dom";
import Nav from './components/Nav';
import Footer from './components/Footer';
import Calendar from './pages/Calendar';
import NotFound from './pages/NotFound';
import Saturday from './pages/Saturday';
import Country from './pages/Country';

function App() {
    
    return (
        <div>
            <Nav></Nav>
            <Routes>
                <Route path="/" element={<Country />} />
                <Route path="/%D7%A9%D7%A2%D7%95%D7%9F" element={<Country />} />
                <Route path="/%D7%9C%D7%95%D7%97-%D7%A9%D7%A0%D7%94" element={<Calendar />} />
                <Route path="/%D7%A9%D7%A2%D7%95%D7%9F/:country" element={<Country />} />
                <Route path="/%D7%96%D7%9E%D7%A0%D7%99-%D7%A9%D7%91%D7%AA" element={<Saturday />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer></Footer>
        </div>
    );
}

export default App;