import { Helmet } from "react-helmet";
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import spacetime from 'spacetime';
import axios from 'axios';
import { formatDate } from "../../utilities/format";
import { DEFAULT_TITLE } from "../../resources/Meta";

const Saturday = () => {

    // TODO: set default "kind of" empty fields for the object below

    const [shabbat, setShabbat] = useState({
        "_id": 157,
        "parasha": "במדבר",
        "heb_date": "כ\"ז אייר התשפ\"ב",
        "date": "2022-05-28T00:00:00",
        "type": "שבת",
        "Jerusalem_in": "00:00:00",
        "Jerusalem_out": "00:00:00",
        "TelAviv_in": "00:00:00",
        "TelAviv_out": "00:00:00",
        "Hayfa_in": "00:00:00",
        "Hayfa_out": "00:00:00",
        "BeerSheva_in": "00:00:00",
        "BeerSheva_out": "00:00:00"
    });

    const { t } = useTranslation();

    const cities = ['Jerusalem', 'TelAviv', 'Hayfa', 'BeerSheva'];

    const initShabbat = async () => {

        let space = spacetime.now();
        space = space.goto('Asia/Jerusalem');
        space = space.weekStart(0);
        space = space.endOf('week');

        const date = space.format();

        const url = 'https://data.gov.il/api/3/action/datastore_search?resource_id=cfe1dd76-a7f8-453a-aa42-88e5db30d567&filters={%22date%22:%22' + date + '%22}';

        const response = await axios.get(url);

        setShabbat(response.data.result.records[0]);

        // TODO: make sure to handle failed request

    }



    // TODO: improve where possible

    useEffect(() => { initShabbat(); }, []);

    const SCard = (props) => {

        // TODO: make cards responsive

        return (
            <Card variant="outlined" className='ml-1 mr-1 basis-full sm:basis-1/3 lg:basis-1/5 mb-1'>
                <CardContent>
                    <Typography>
                        {props.city}
                    </Typography>
                    <Typography variant="h4">
                        {props.time}
                    </Typography>
                </CardContent>
            </Card>
        )
    }

    return (

        <div className='container p-2 md:p-0 min-h-screen mx-auto'>

            <Helmet>
                <title>{t('Shabbat entry and exit times Parshat')} {shabbat['parasha']} - {DEFAULT_TITLE}</title>
                <meta name="description" content="Show shabat" />
            </Helmet>

            <h1 className="text-5xl mt-5 mb-5">{t('Shabbat entry and exit times Parshat')} {shabbat['parasha']}</h1>

            <div className="text-3xl">{t('Hebrew date')}: {shabbat['heb_date']}</div>
            <div className="text-3xl">{t('Gregorian Date')}: {formatDate(shabbat['date'])}</div>

            <div className="text-right text-4xl font-medium mt-5 mb-5">כניסת שבת</div>

            <Grid container alignItems="center" className='flex flex-wrap flex-row'>

                {cities.map((city) => <SCard key={city} time={shabbat[`${city}_in`].slice(0, -3)} city={t(city)} />)}

            </Grid>

            <div className="text-right text-4xl font-medium mt-5 mb-5">צאת שבת</div>

            <Grid container alignItems="center" className='flex flex-wrap flex-row'>
                
                {cities.map((city) => <SCard key={city} time={shabbat[`${city}_out`].slice(0, -3)} city={t(city)} />)}

            </Grid>

        </div>
    )

};

export default Saturday;