import './index.css';
import { Helmet } from "react-helmet";
import { TextField, Typography, Autocomplete } from '@mui/material';
import { HebrewDateEvent, HDate } from '@hebcal/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import ReactCalendar from 'react-calendar'
import Holidays from 'date-holidays';
import { DEFAULT_DESCRIPTION, DEFAULT_TITLE } from '../../resources/Meta';

const yearOptions = Array.from({ length: 131 }, (x, i) => {

    return { label: (2100 - i).toString() };

});

const holidays = new Holidays('IL');
holidays.setLanguages('he');

const Calendar = () => {

    const [date, setDate] = useState(new Date());
    const [hebrewDate, setHebrewDate] = useState(new HDate(date));

    const { t } = useTranslation();

    const months = [
        { number: 0, label: t('January') },
        { number: 1, label: t('February') },
        { number: 2, label: t('March') },
        { number: 3, label: t('April') },
        { number: 4, label: t('May') },
        { number: 5, label: t('June') },
        { number: 6, label: t('July') },
        { number: 7, label: t('August') },
        { number: 8, label: t('September') },
        { number: 9, label: t('October') },
        { number: 10, label: t('November') },
        { number: 11, label: t('December') }
    ]

    const formatDay = (locale, date) => {

        const hebrewDate = new HDate(date);
        const hebrewEvent = new HebrewDateEvent(hebrewDate);
        const holiday = holidays.isHoliday(date);

        return (
            <div className='text-right'>
                <Typography variant="h6" component="div">{hebrewDate.getDate()}</Typography>
                <small>{hebrewEvent.renderBrief('he')}</small>
                <div>
                    <small className='text-secondary'>{holiday ? holiday[0].name : <></>}</small>
                </div>
            </div>

        )
    };

    const onChangeYear = (event, data) => {

        const newDate = new Date(date);
        const year = parseInt(data.label);
        newDate.setFullYear(year);
        setDate(newDate);

    };

    const onChangeMonth = (event, data) => {

        const newDate = new Date(date);
        newDate.setMonth(data.number);
        setDate(newDate);

    };

    useEffect(() => { setHebrewDate(new HDate(date)); }, [date]);

    return (
        <div className='container mx-auto p-2 md:p-0 mt-5 min-h-screen'>

            <Helmet>
                <title>{t('Hebrew and foreign calendar')} - {DEFAULT_TITLE}</title>
                <meta name="description" content={`${t('Hebrew and foreign calendar with detailed information about holidays and national days')} - ${DEFAULT_DESCRIPTION}`} />
            </Helmet>

            <Typography variant="h4" component="div" className="md:!text-6xl" gutterBottom>
                {t('Hebrew and foreign calendar')}
            </Typography>

            <div className="flex align-center mb-5 items-center">

                <ArrowForward fontSize="large" sx={{ color: 'white' }} className='bg-primary rounded-full ml-auto md:ml-5' />

                <div className="!text-4xl hidden md:block md:ml-auto">
                    {t(hebrewDate.getMonthName())} - {date.getFullYear()}
                </div>

                <Autocomplete
                    disableClearable
                    options={yearOptions}
                    sx={{ width: 100 }}
                    value={date.getFullYear().toString()}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    onChange={onChangeYear}
                    className="ml-1"
                    renderInput={(params) => <TextField {...params} label={t("Year")} />}
                />

                <Autocomplete
                    disableClearable
                    options={months}
                    sx={{ width: 100 }}
                    value={months[date.getMonth()]}
                    className="mr-1"
                    isOptionEqualToValue={(option, value) => option.number === value.number}
                    onChange={onChangeMonth}
                    renderInput={(params) => <TextField {...params} label={t("Month")} />}
                />

                <ArrowBack fontSize="large" sx={{ color: 'white' }} className='bg-primary rounded-full mr-auto md:mr-5' />

            </div>

            <ReactCalendar
                className='!w-full'
                locale={"he-IL"}
                calendarType='Hebrew'
                onChange={setDate}
                value={date}
                showNavigation={false}
                formatDay={formatDay}
            />

        </div>
    )

};

export default Calendar;