import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Helmet from 'react-helmet';
import { DEFAULT_TITLE } from "../../resources/Meta";

const NotFound = () => {

    const { t } = useTranslation();

    return (
        <div className="container mx-auto">

            <Helmet>
                <title>{t('Page not found')} - {DEFAULT_TITLE}</title>
            </Helmet>

            <div className="">
            <Typography variant="h1" component="div">
                {t('Page not found')}
            </Typography>

            <Typography variant="body1" display="block">
                {t("It seems to be that the page you're searching for has been moved or deleted. If you believe this is an error, please let us know.")}
            </Typography>

            </div>


        </div>
    )

};

export default NotFound;