
const formatDate = (date) => {

    if (!date) return 'error';

    let format = new Date(date); // date could be represented in milliseconds

    format = format.toISOString().substring(0, 10).split('-').reverse().join('/');

    return format;

}

// TODO: fix to use for hebrew country routes
const formatString = (str) => {

    str = str.replaceAll(/[\u0590-\u05FF]/g, ' ');
    return str;

}

module.exports = { formatString, formatDate };