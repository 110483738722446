import { Helmet } from "react-helmet";
import React, { useEffect, useState } from 'react';
import countries from '../../countries.json';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Clock from 'react-live-clock';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Link } from "@mui/material";
import { DEFAULT_TITLE } from "../../resources/Meta";

const Country = () => {

    const [timezone, setTimezone] = useState();
    const [name, setName] = useState();
    const { t } = useTranslation();
    let navigate = useNavigate();
    let { country = t('Israel') } = useParams();

    const handleChange = (event, data) => {

        const countryName = data.label.replaceAll(' ', '-');
        navigate(`/שעון/${countryName}`, { replace: true });

    };

    useEffect(() => {

        // TODO: remove all special chars from string
        // ?example: const test = country.replaceAll(/[\u0590-\u05FF]/g, ' ');

        const countryRoute = country.replaceAll('-', ' ');
        let countryData = countries.find(data => data.label === countryRoute);

        setName(countryData.label);
        setTimezone(countryData.timezone);

    }, [country]);

    // TODO: center this component within the screen
    // TODO: add navigation to links below

    return (
        <div className="container min-h-screen mx-auto align-center center content-center items-center grid text-center">

            <Helmet>
                <title>{`${t('What is the time in')}${name} - ${DEFAULT_TITLE}`}</title>
                <meta name="description" content="Show time" />
            </Helmet>

            <h1 className="text-7xl mt-5 mb-5">{t('The time in')}{name}</h1>

            <Clock format={'HH:mm:ss'} className="text-[18vw] text-primary" ticking={true} timezone={timezone} />

            <div className='text-3xl flex flex-row items-center'>

                <Link className="pl-2">{t('United States')},</Link>
                <Link className="pl-2">{t('China')},</Link>
                <Link className="pl-2">{t('Italy')},</Link>
                <Link className="pl-2">{t('Turkey')},</Link>
                <Link className="pl-2">{t('Mexico')}</Link>

                <span className="pl-2">או</span>

                <Autocomplete
                    options={countries}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    renderInput={(params) => <TextField {...params} label={t('Country')} />}
                />

            </div>

        </div>
    )

};

export default Country;